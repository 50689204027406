import './App.css';

function App() {
  return (
    <div className='app'>
      <video
        className='hero-video'
        src='scribes_loop.mp4'
        autoPlay
        muted
        loop
      />

      <div className='content'>
        <h1>Welcome to Crynode</h1>
        <p>
          We are building a network of AI Nodes, each serving a distinct role
          within a growing ecosystem. Stay tuned for more details and beta
          invites! Reach us at{' '}
          <a href='mailto:hi@crynode.com'>hi@crynode.com</a>
        </p>

        <h2>AICRYNODE Token</h2>
        <p>
          Our utility token, AICRYNODE, powers the Crynode network on the Solana
          blockchain, enabling access to services across our AI Crystal Nodes.
        </p>

        <h2>Join our community</h2>
        <p className='links'>
          <a
            href='https://twitter.com/aicrynode'
            target='_blank'
            rel='noopener noreferrer'
          >
            X @aicrynode
          </a>
          <a
            href='https://t.me/aicrynode'
            target='_blank'
            rel='noopener noreferrer'
          >
            Telegram
          </a>
          <a
            href='https://youtube.com/@crynode'
            target='_blank'
            rel='noopener noreferrer'
          >
            Youtube
          </a>
        </p>

        <h2>Don't trust - verify</h2>
        <p>Verify the AICRYNODE token:</p>
        <p className='links'>
          <a
            href='https://solsniffer.com/scanner/PD11M8MB8qQUAiWzyEK4JwfS8rt7Set6av6a5JYpump'
            target='_blank'
            rel='noopener noreferrer'
          >
            SolSniffer
          </a>
          <a
            href='https://rugcheck.xyz/tokens/PD11M8MB8qQUAiWzyEK4JwfS8rt7Set6av6a5JYpump'
            target='_blank'
            rel='noopener noreferrer'
          >
            RugCheck
          </a>
          <a
            href='https://check.quillai.network/solana/PD11M8MB8qQUAiWzyEK4JwfS8rt7Set6av6a5JYpump'
            target='_blank'
            rel='noopener noreferrer'
          >
            Quill
          </a>
          <a
            href='https://app.bubblemaps.io/sol/token/PD11M8MB8qQUAiWzyEK4JwfS8rt7Set6av6a5JYpump'
            target='_blank'
            rel='noopener noreferrer'
          >
            BubbleMaps
          </a>
          <a
            href='https://solscan.io/token/PD11M8MB8qQUAiWzyEK4JwfS8rt7Set6av6a5JYpump'
            target='_blank'
            rel='noopener noreferrer'
          >
            Solscan
          </a>
        </p>

        <h2>DEX</h2>
        <p className='links'>
          <a
            href='https://www.geckoterminal.com/solana/pools/6Y6ABmtWu1uxacwyNwWf7fHA7nsHa6krXhseJqU7VT88'
            target='_blank'
            rel='noopener noreferrer'
          >
            Geckoterminal
          </a>
          <a
            href='https://www.dextools.io/app/en/solana/pair-explorer/6Y6ABmtWu1uxacwyNwWf7fHA7nsHa6krXhseJqU7VT88?t=1730200142984'
            target='_blank'
            rel='noopener noreferrer'
          >
            Dextools
          </a>
          <a
            href='https://birdeye.so/token/PD11M8MB8qQUAiWzyEK4JwfS8rt7Set6av6a5JYpump?chain=solana'
            target='_blank'
            rel='noopener noreferrer'
          >
            Birdeye
          </a>
          <a
            href='https://jup.ag/swap/SOL-AICRYNODE'
            target='_blank'
            rel='noopener noreferrer'
          >
            Jup.ag
          </a>
        </p>

        <h2>Token Details</h2>
        <div className='token-details'>
          <div>
            <strong>Symbol:</strong> AICRYNODE
          </div>
          <div>
            <strong>Token Name:</strong> AI Crystal Node
          </div>
          <div>
            <strong>Token Address: </strong>
            <a
              href='https://solscan.io/token/PD11M8MB8qQUAiWzyEK4JwfS8rt7Set6av6a5JYpump'
              target='_blank'
              rel='noopener noreferrer'
            >
              PD11M8MB8qQUAiWzyEK4JwfS8rt7Set6av6a5JYpump
            </a>
          </div>
          <div>
            <strong>Token Decimals:</strong> 6
          </div>
          <div>
            <strong>Fixed Supply:</strong> 999,983,378.084998
          </div>
          <div>
            The token was launched fairly, with no presale and no team
            allocation. Every token holder purchased the token with real money.
            Market participants determine the price through natural market
            discovery, not through fixed pricing set by VCs in an over-hyped
            launch. This project is bootstrapped and self-funded with our own
            savings, allowing us full independence and a strong focus on serving
            the community.
          </div>
          <div>
            The supply is fixed, with no possibility of minting additional
            tokens - 0% inflation, no risk of rug pulls, honeypots, or VC
            control: no unlocks, cliffs, or vesting periods.
          </div>

          <div>
            <strong>Founding Team's Wallet (0.7% of supply): </strong>
            <a
              href='https://solscan.io/account/Hc9GGvBoiPrQaEpUhEvL7DFnvJDT3wno5FphJ3M1kCjD'
              target='_blank'
              rel='noopener noreferrer'
            >
              Hc9GGvBoiPrQaEpUhEvL7DFnvJDT3wno5FphJ3M1kCjD
            </a>
          </div>
        </div>

        <h2>Pure Intelligence Altar</h2>
        <video
          className='hero-video'
          src='altar.mp4'
          autoPlay
          muted
          loop
        />

        <div>
          <a
            href='https://solscan.io/account/AiQwFY7zDR3x3TqJHezhaG27he269TWkbBy7FT7X2dyX'
            target='_blank'
            rel='noopener noreferrer'
          >
            AiQwFY7zDR3x3TqJHezhaG27he269TWkbBy7FT7X2dyX
          </a>{' '}
          crynode-altar.sol
        </div>

        <h2>Typen sees all</h2>
        <video
          className='hero-video'
          src='typen.mp4'
          autoPlay
          muted
          loop
        />
        <p>
          Don’t day-trade our token. This is a long-term project that will take
          months of development to reach its true potential.
        </p>

        <h2>Join the network</h2>
        <p>
          Want to get involved? Send a simple CV to{' '}
          <a href='mailto:hi@crynode.com'>hi@crynode.com</a>. We're especially
          seeking developers skilled in JavaScript / Fullstack.
        </p>

        <h2>Github</h2>
        <p>
          We’ll open-source multiple elements of our network and provide
          examples on how to set up your own Crynode. Coming soon. Follow us on
          Github:{' '}
          <a
            href='https://github.com/crynode'
            target='_blank'
            rel='noopener noreferrer'
          >
            https://github.com/crynode
          </a>
        </p>
      </div>
    </div>
  );
}

export default App;
